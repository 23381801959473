import React,{useEffect, useState} from 'react'
import './CheckinConfirmation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import CustomerAPIService from '../../../services/ApiService'
import { checkinCustomer } from '../ServiceCheckin'
import NavbarAfterLoggedin from '../../Navbar/NavbarAfterLoggedin/NavbarAfterLoggedin'

const CheckinConfirmation = () => {
  const [currentCount, setCurrentCount] = useState(1)
  const increment = () =>{
    if (currentCount < 10) {
      setCurrentCount(currentCount + 1)
    }
  }
  const decrement = () =>{
    if (currentCount > 1) {
      setCurrentCount(currentCount - 1)
    }
  }
  const [waitTime,setWaitTime] =useState('')
  useEffect(()=>{
    const storedSelectedBusiness = sessionStorage.getItem('selectedBusiness')
    if(storedSelectedBusiness){
      console.log(JSON.parse(storedSelectedBusiness).serviceDuration)
      setWaitTime(JSON.parse(storedSelectedBusiness).serviceDuration)
    }
  })
  const navigate = useNavigate()
 
  const handleCheckin = () =>{
    checkinCustomer(navigate,currentCount)
  }

  return (
    <>
    <NavbarAfterLoggedin/>
    <div className="checkin-page"> 
        <div className="checkin-container">
        <label className='checkinconf-header'>Check In</label>
    <div className='checkinconf-phone'>
        <label className='checklbl_ppltxt'># of people<br></br>checkin</label>
        <FontAwesomeIcon onClick={decrement}icon={faMinus} className="green-icon circle-icon" />
      <label className='checkconfirm-phonelbl'>{currentCount}</label>
      <FontAwesomeIcon onClick={increment} icon={faPlus} className="green-icon circle-icon" />
    </div>
    <div className='checkinconf-button'>
        <label>Est. Wait Time: <span>{waitTime} Minutes</span></label>
        <button className='btn__checkmein' onClick={handleCheckin}>CHECK ME IN</button>
    </div>
    </div>
    </div>
    </>
  )
}

export default CheckinConfirmation