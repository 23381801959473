import React, {useEffect,useState} from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { IoLocationOutline } from "react-icons/io5";
import CustomerAPIService from '../../services/ApiService'
import { FaAngleDown } from "react-icons/fa6";
import './settingsDrawer.css'
import { IoMdClose } from "react-icons/io";
import { getListOfCity } from '../Search/ServiceSearch';

export default function SettingsDrawer({setCity}) {
    const [state, setState] = React.useState({
        right: false,
      })

      const [name,setName] = useState('')
      useEffect(()=>{
        const user = CustomerAPIService.getCurrentUser()
          if(user){
              setName(user.name)
          }
          try{
          const customerCityName = JSON.parse(localStorage.getItem("CustomerCityName"))
          if(customerCityName.name){
            setSelectedCity(customerCityName);
            setCity(customerCityName.name)
          }
        }catch(e){
          console.log(e)
        }
      },[])
      
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return
        }
        setState({ ...state, [anchor]: open })
      }
      const [filteredSuggestions, setFilteredSuggestions] = useState([])
      const [cityInput,setCityInput] = useState('')
      const [selectedCity,setSelectedCity] = useState({name:'Other',state:'Tamil Nadu'})
      const onChangeSearchCity=(e)=>{
        const cityStartsWith = e.target.value
        getListOfCity(setCityInput,cityStartsWith,setFilteredSuggestions,"Tamil Nadu")
      }

      const onSelectCity=(city)=>{
        console.log('selected city',city)
        setSelectedCity(city)
        localStorage.setItem("CustomerCityName", JSON.stringify(city));   
        setCity(city.name)
        setState({ ...state, ['left']: false })
      }
    
      const list = (anchor) => (
        <Box
          sx={{
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'end'
          }}
          role="presentation"
        >
          <div>
            <IoMdClose className='icon__drawerclose' onClick={toggleDrawer('left', false)}/>
            <div className='container__citysearch'>
              <input type='text' value={cityInput} onChange={onChangeSearchCity} placeholder='Search for a city...'></input>
              <button onClick={()=>setCityInput('')}>Cancel</button>
            </div>
            {filteredSuggestions.map((city)=>(
                <div onClick={()=>onSelectCity(city)} className='city__results'>
                  <div>
                      <IoLocationOutline/>
                  </div>
                  <div className='city_result_details'>
                      <h3>{city.name}</h3>
                      <h4>{city.state}, India</h4>
                   </div>
                </div>
            ))}
           
          </div>
        </Box>
      )
      return (
        <div>
          {['left'].map((anchor) => (
            <React.Fragment>
            <div className="container__location" onClick={toggleDrawer(anchor, true)}>
            <IoLocationOutline className='icon__location'/>
            <h5>{selectedCity.name}</h5>
            <p>,{selectedCity.state}, India</p>
            <FaAngleDown className='icon__location__dropdown' />
            </div>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      )
}