import React from 'react'
import '../SearchBusiness.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPhone, faMapMarkerAlt ,faGlobe } from '@fortawesome/free-solid-svg-icons'


const BusinessDetails = ({ phone, address, website ,status}) => {
  return (
    <>
    <div className="left">
            <div className="info">
            {status==='STATUS_OPEN'?(
              <div className="info-item">
              <FontAwesomeIcon icon={faCircle} style={{ color: 'green', fontSize: '15px' }} /> 
              <p>Opened</p>
              </div>
            ):(<div className="info-item">
            <FontAwesomeIcon icon={faCircle} style={{ color: 'red', fontSize: '15px' }} /> 
            <p>Closed</p>
            </div>)}
            
              <div className="info-item">
                <FontAwesomeIcon icon={faPhone} color="#007bff" />
                <p>{phone}</p>
              </div>
              <div className="info-item">
                <FontAwesomeIcon icon={faMapMarkerAlt} color="#007bff" />
                <p>{address}</p>
              </div>
              <div className="info-item">
                <FontAwesomeIcon icon={faGlobe} color="#007bff" />
                <p><a href={"https://" + website} target="_blank" rel="noopener noreferrer">{website}</a></p>
              </div>
            </div>
          </div>
    </>
    
  )
}

export default BusinessDetails