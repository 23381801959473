import React from 'react'
import { Link, json, useNavigate } from 'react-router-dom'
import '../SearchBusiness.css'
import { StoreStatusEnum } from '../../../data/contants'
import TokenVerification from '../../../services/TokenVerification'

const BusinessStatus = ({businessData}) => {
  const navigate = useNavigate()
  const isnotLoggedIn = TokenVerification.isTokenExpired()
  const handleCardClick = (selectedCard) => {
    if(isnotLoggedIn){
      navigate('/login')
    }else{
      sessionStorage.setItem("selectedBusiness",JSON.stringify(selectedCard))
      console.log(selectedCard)
      navigate('/checkin')
    }
  }

  return (
    <div className="right">
          {businessData.status === StoreStatusEnum.OPEN && (
            <>
            <div className="direction-icon">
              <p className="direction-label1">{businessData.serviceDuration}</p>
              <p className="direction-label2">MIN</p>
              <p className="direction-label3">Estimated wait</p>
            </div>
            <button onClick={()=>handleCardClick(businessData)} className="checkinbtn-enable">Check-in</button>
            </>
            )}
            {businessData.status === StoreStatusEnum.CLOSED && (
            <>
            <div className="direction-icon">
              <p className="direction-label1">--</p>
              <p className="direction-label2">---</p>
              <p className="direction-label3">Estimated wait</p>
            </div>
            <button disabled className="checkinbtn-disable">Check-in</button>
            </>
            )}
            {businessData.status === StoreStatusEnum.TEMPORARILY_CLOSED && (
            <>
            <div className="direction-icon">
              <p className="direction-label1">--</p>
              <p className="direction-label2">---</p>
              <p className="direction-label3">Estimated wait</p>
            </div>
            <button disabled className="checkinbtn-disable">Check-in</button>
            </>
            )}
          </div>
  )
}

export default BusinessStatus