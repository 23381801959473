import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import "./navbar.css";
import { MdOutlineClose } from "react-icons/md";
import { links } from "../../data/data";
import { FaAngleDown } from "react-icons/fa6";
import SettingsDrawer from "../Settings/SettingsDrawer";

const Navbar = () => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <nav>
      <div className="container nav__container">
        <div className="navbar__left">
        <Link to="/" onClick={() => setIsNavShowing(false)} className="logo">
          <img className="navbar__logo"  src={logo} alt="Nav Logo"></img>
        </Link>
        </div>
        <ul
          className={`nav__links ${isNavShowing ? "show__nav" : "hide__nav"}`}
        >
          {links.map(({ name, path }, index) => {
            return (
              <li>
                <NavLink
                  to={path}
                  className={({ isActive }) => (isActive ? "active-nav" : "")}
                  onClick={() => setIsNavShowing((prev) => !prev)}
                >
                  {name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <button
          className="nav__toggle-btn"
          onClick={() => setIsNavShowing((prev) => !prev)}
        >
          {isNavShowing ? <MdOutlineClose /> : <HiMiniBars3CenterLeft />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
