import { Link } from "react-router-dom";
import Image from "../../../images/long-queue.png";
import "./homeHeader.css";
import { CommonInfo } from "../../../data/contants";

const HomeHeader = () => {
  return (
    <header className="main__header">
      <div className="main__header-container">
        <div className="header__content">
          <h1>
          "Find Your Favorites"
          </h1>
          <p>Looking for a specific business or service? Type it in and let guide you to the best spots in town!</p>
          {/* <h3>#CheckInWith{CommonInfo.SITENAME}</h3> */}
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
