import React from 'react';
import './countryComboBox.css'
import { useState } from 'react';
import india from './CountryFlagImages/Flag_of_India.svg'
import unitedStates from './CountryFlagImages/Flag_of_United_States.svg'
import japan from './CountryFlagImages/Flag_of_Japan.svg'
import { WidthFull } from '@mui/icons-material';
const CountryComboBox = ({ onSelect }) => {
    const [selectedCountry, setSelectedCountry] = useState( { countryName: 'India', countryCode: '91', image: india });
    const [isOpen, setIsOpen] = useState(false);
    const countries = [
        { countryName: 'India', countryCode: '91', image: india },
        { countryName: 'United States', countryCode: '1', image: unitedStates},
        { countryName: 'Japan', countryCode: '81', image: japan},
      ];
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    const handleSelect = (index) => {
      setSelectedCountry(countries[index]);
      onSelect(countries[index]);
      setIsOpen(false);
    };
  
    return (
      <div className="country-combobox">
        <div className="selected" onClick={handleToggle}>
          {selectedCountry ? (
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',width:'100%'}}>
              <img style={{height:'100%'}} src={selectedCountry.image} alt={selectedCountry.countryName} />
              <span className='span_countrycode'>
                {/* {selectedCountry.countryName}  */}
                {"+"+selectedCountry.countryCode}
              </span>
            </div>
          ) : (
            <span>Select a country</span>
          )}
        </div>
        {isOpen && (
          <div className="options">
            {countries.map((country, index) => (
              <div key={index} onClick={() => handleSelect(index)}>
                <img src={country.image} alt={country.countryName} />
                {/* {country.countryName}  */}
                {"+"+country.countryCode}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default CountryComboBox;
