export const validateUserName = (userName,setUserNameError) =>{
    const numberRegex = /^\d{10}$/
    const emailRegex = /\S+@\S+\.\S+/
    if (numberRegex.test(userName)||emailRegex.test(userName)) {
        return true
    }else {
        setUserNameError("please enter valid mobile or email")
      return false
    }
}

export const validatePassword = (password,setPasswordError) =>{
    if(password.length>=8){
        return true
      }
      setPasswordError("password length must have 8 characters")
      return false
}

export const validateIsMobileOrEmail = (userName,setUserNameError,setEmail,setMobileNumber,setUserNameIsEmail,setUserNameIsMobile) =>{
    const numberRegex = /^\d{10}$/
    const emailRegex = /@.*\.com$/
    if (numberRegex.test(userName)) {
        setMobileNumber(userName)
        setUserNameIsMobile(true)
        return "mobile"
    } else if (emailRegex.test(userName)) {
        setEmail(userName)
        setUserNameIsEmail(true)
        return "email"
    } else {
      setUserNameError("please enter valid mobile or email")
      return ""
    }
}

export const validateMobile = (mobileNumber,setMobileNumberError) =>{
  const numberRegex = /^\d{10}$/
  if (!numberRegex.test(mobileNumber)) {
      setMobileNumberError("please enter valid mobile number")
      return false
  }
  return true
}

export const validateEmail = (email,setEmailError) =>{
  const emailRegex = /\S+@\S+\.\S+/
  if (!emailRegex.test(email)) {
      setEmailError("please enter valid mobile number")
      return false
  }
  return true

}

export const userNameIsMobileOrEmail =(userName,setEmail,setMobileNumber)=>{
  const numberRegex = /^\d{10}$/
  const emailRegex = /@.*\.com$/
  if (numberRegex.test(userName)) {
      setMobileNumber(userName)
  } else if (emailRegex.test(userName)) {
      setEmail(userName)
  }
} 

export const validateIsOtp = (otp,setOTPError) =>{
  const otpRegex = /^\d{6}$/
  if(otpRegex.test(otp)){
    return true
  }else{
    setOTPError('please check the otp')
    return false
  }
}

export const validateName = (name,setNameError) =>{
    if (name.length>0) {
        setNameError('')
        return true
      } else {
        setNameError('name is required')
        return false
      }
}