import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { ApiList } from '../data/contants'

let stompClient = null

const connect = (onConnected, onMessageReceived, onCustomerDataReceived,businessName) => {
  const socket = new SockJS(ApiList.WEBSOCKET_URL+'reception')
        stompClient = new Client({
            webSocketFactory: () => socket, 
            onConnect: onConnected,
            onStompError: (frame) => {
              console.error(frame)
            },
          })
        
          stompClient.onConnect = (frame) => {
            stompClient.subscribe('/topic/hello', onMessageReceived)
            stompClient.subscribe('/user/'+businessName+'/topic/message', onCustomerDataReceived)
          }
          stompClient.activate()
}

const disconnect = () => {
  if (stompClient) {
    stompClient.deactivate()
  }
}

const sendMessage = () => {
  stompClient.publish({ destination: '/app/message', body: {} })
}

export { connect, disconnect, sendMessage }
