import LoginForm from "../../components/Login/LoginForm";
import Navbar from "../../components/Navbar/Navbar";
import "./login.css";

const Login = () => {
  return (
    <>
      <Navbar/>
      <LoginForm/>
    </>
  );
};

export default Login;
