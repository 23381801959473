import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import "./navbar.css";
import { MdOutlineClose } from "react-icons/md";
import { links } from "../../data/data";
import { FaAngleDown } from "react-icons/fa6";
import SettingsDrawer from "../Settings/SettingsDrawer";
import AccountMenu from "./NavbarAfterLoggedin/Menu/AccountMenu";

const NavbarHome = () => {
    const [isNavShowing, setIsNavShowing] = useState(false);

    return (
      <nav>
        <div className="container nav__container">
          <Link to="/" onClick={() => setIsNavShowing(false)} className="logo">
            <img className="navbar__logo" src={logo} alt="Nav Logo"></img>
          </Link>
          <ul
            className={"nav__ul"}
          >
             <AccountMenu/>
          </ul>
        </div>
      </nav> 
    );
};

export default NavbarHome;
