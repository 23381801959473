import React from "react"

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}

const AuthVerify = (props) => {
  props.history.listen(() => {
    const user = JSON.parse(localStorage.getItem("c_user_token"))

    if (user) {
      const decodedJwt = parseJwt(user.accessToken)

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut()
      }
    }
  })

  return <div></div>
}

const isTokenExpired = () => {
  const user = JSON.parse(localStorage.getItem("c_user_token"))
  if (user) {
    const decodedJwt = parseJwt(user.token)
  if (!decodedJwt || !decodedJwt.exp) {
    return true
  }
  return decodedJwt.exp * 1000 < Date.now()
  }
  return true
}




const TokenVerification = {
  AuthVerify,
  isTokenExpired
}

export default TokenVerification