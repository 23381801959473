import Navbar from "../../components/Navbar/Navbar";
import SignupForm from "../../components/Signup/SignupForm";
import "./signup.css";

const Signup = () => {
  return (
    <>
      <Navbar/>
      <SignupForm/>
    </>
  );
};

export default Signup;
