import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";
import Signup from "./pages/signup/Signup";
import Navbar from "./components/Navbar/Navbar";
import CheckinConfirmation from "./components/Checkin/Checkin-Confirmation/CheckinConfirmation";
import CheckinProgress from "./components/Checkin/Checkin-progress/CheckinProgress";
import { useEffect, useState } from "react";
import TokenVerification from "./services/TokenVerification";
import NavbarAfterLoggedin from "./components/Navbar/NavbarAfterLoggedin/NavbarAfterLoggedin";

const App = () => {
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  useEffect(()=>{
    setIsLoggedIn(!TokenVerification.isTokenExpired())
  },[])
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        {!isLoggedIn &&<Route path="/login" element={<Login />} />}
        {!isLoggedIn &&<Route path="/signup" element={<Signup />} />}
        {isLoggedIn &&<Route path="/checkin" element={<CheckinConfirmation/>}/>}
        {isLoggedIn &&<Route path="/checkin/progress" element={<CheckinProgress/>}/>}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
