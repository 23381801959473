export const CommonInfo = {
    SITENAME : 'Qbrother'
}

export const ApiList = {
    CUSTOMER_API_URL : "https://test.spotinext.com/c/",
    BUSINESS_API_URL : "https://test.spotinext.com/b/api/",
    WEBSOCKET_URL: 'https://test.spotinext.com/'
}

// export const ApiList = {           
//     CUSTOMER_API_URL : "http://192.168.29.122:8080/",
//     BUSINESS_API_URL : "http://192.168.29.122:8081/api/",
//     WEBSOCKET_URL: 'http://192.168.29.122:8082/'
// }

export const CheckinStatusEnum = {
    STATUS_ENROLL: 'STATUS_ENROLL',
    STATUS_CHECK_IN: 'STATUS_CHECK_IN',
    STATUS_NOT_CHECK_IN: 'not-checkedIn',
    STATUS_SERVICE_IN_PROGRESS: 'STATUS_SERVICE_IN_PROGRESS',
    STATUS_SERVICE_COMPLETED: 'STATUS_SERVICE_COMPLETED',
    STATUS_CANCELLED: 'STATUS_CANCELLED',
  };

export const StoreStatusEnum = {
    OPEN: 'STATUS_OPEN',
    CLOSED: 'STATUS_CLOSED',
    TEMPORARILY_CLOSED:'STATUS_TEMPORARILY_CLOSED'
}