import { CheckinStatusEnum } from "../../data/contants"
import CustomerAPIService from "../../services/ApiService"

export const checkinCustomer = (navigate,currentCount) =>{
    const storedSelectedBusiness = sessionStorage.getItem('selectedBusiness')
    const user = CustomerAPIService.getCurrentUser()
    if (storedSelectedBusiness&&user) {
      const selectedBusiness = JSON.parse(storedSelectedBusiness)
      CustomerAPIService.Checkin(user.userName,selectedBusiness.userName,currentCount)
      .then((response)=>{
          navigate("/")
      }).catch((error)=>{
        console.log(error)
      })
    }
  }

 export const CancelCheckIn=(user,customerCheckinData,window)=>{
    if (user !== null) {
       CustomerAPIService.updateCheckInStatus(customerCheckinData.businessId,user.userName,CheckinStatusEnum.STATUS_CANCELLED).then(
        (response)=>{
           console.log(response.data)
           window.location.reload()
        }
       ).catch((error)=>{
        console.log(error)
      })
    }
   }