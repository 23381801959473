import CustomerAPIService from "../../services/ApiService"

export const LoginCustomer = (name,email,mobileNumber,password,navigate,window,setErrorMessage,setLoading,setSignupSuccess,setUsernameErrorMessage)=>{
    CustomerAPIService.register(name,email,mobileNumber, password).then(
        (response) => {
          setSignupSuccess(true)
          setTimeout(() => {
            navigate("/login")
            setLoading(false)
            window.location.reload()
          }, 1500)
        },
      ).catch((error)=>{
      console.log(error)
      setLoading(false)
      setUsernameErrorMessage(error.response.data)
    })
}

export const sendEmailOTPtoCustomer = (email,setSignupStep,setLoading) =>{
    CustomerAPIService.sendEmailOTP(email).then((response)=>{
        if(response){
          console.log(response.data)
          setLoading(false)
          setSignupStep(2)
         }
       }).catch((error)=>{
           console.log(error)
           setLoading(false)
       })
} 


export const verifyCustomerEmailOTP=(email,otp,setSignupStep,setOTPError)=>{
    CustomerAPIService.verifyEmailOTP(email,otp)
    .then((response)=>{
      console.log(response)
      setSignupStep(3)
    })
    .catch((error)=>{
      console.log(error)
      setOTPError('Invalid OTP')
    })
}


export const sendMobileOTPtoCustomer = (mobile,setSignupStep,countryCode,setLoading,setotpErrorMessage) =>{
  CustomerAPIService.sendMobileOTP(mobile,countryCode).then((response)=>{
      if(response){
        console.log(response)
        setLoading(false)
        if(response.data.type==='success'){
          setSignupStep(2)
        }else{
          console.log(response.data.message)
          setotpErrorMessage(response.data.message)
        }
       }
     }).catch((error)=>{
         console.log(error)
         setLoading(false)
     })
} 

export const verifyCustomerMobileOTP=(mobile,otp,setSignupStep,setOTPError)=>{
  CustomerAPIService.verifyMobileOTP(mobile,otp)
  .then((response)=>{
    console.log(response)
    setSignupStep(3)
  })
  .catch((error)=>{
    console.log(error)
    setOTPError('Invalid OTP')
  })
}
