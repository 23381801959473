import CustomerAPIService from "../../services/ApiService"

export const searchBusiness = (searchTerm,selectedCity,setResponseData,setFilteredData) =>{
    CustomerAPIService.search(searchTerm,selectedCity).then(
        (response) => {
          setResponseData(response)
          console.log(response.data)
          setFilteredData(response.data)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
            setResponseData([])
            setFilteredData([])
            console.log(resMessage)
        }
      )
}


export const getListOfCity =(setInputValue,newInputValue,setFilteredSuggestions,state)=>{
    setInputValue(newInputValue)
    CustomerAPIService.citySearchFilter(newInputValue)
      .then((response) => {
        setFilteredSuggestions(response.data)
        console.log("city list",response.data)
      })
      .catch((error) => {
        
        console.log('Error fetching city list:', error)
      })
}


export const getListOfState=(setFilteredSuggestions)=>{
    CustomerAPIService.getStateList("India")
    .then((response) => {
      setFilteredSuggestions(response.data)
    })
    .catch((error) => {
      console.log('Error fetching state list:', error)
    })
}