import React from 'react'
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import CustomerAPIService from '../../services/ApiService';

const Captcha = () => {
    const [captchaValue, setCaptchaValue] = useState('');
    const onChangeCaptcha = (value) => {
      setCaptchaValue(value);
      CustomerAPIService.verifyCaptcha(value).then((response)=>{
        console.log(response)
      }).catch((error)=>{
        console.log(error)
      })
      console.log(value)
    };
  return (
    <div className='captcha'>
    <ReCAPTCHA sitekey="6Lea-UspAAAAANKgjx7dkcXMNEKH0Y1jWlaYZ6nX" onChange={onChangeCaptcha}/>
    </div>
  )
}

export default Captcha