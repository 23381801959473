import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './SignupForm.css'
import { TextField } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { validateIsOtp, validateIsMobileOrEmail, validateName, validatePassword, userNameIsMobileOrEmail, validateMobile, validateEmail } from '../../data/inputValidations'
import { LoginCustomer, sendEmailOTPtoCustomer, sendMobileOTPtoCustomer, verifyCustomerEmailOTP, verifyCustomerMobileOTP } from './ServiceSignup'
import CountryComboBox from './Features/CountrySelection/CountryComboBox'
import Loading from '../Features/Loading'
import CustomizedSnackbar from '../Features/SuccessSnackbar'
import Captcha from '../Features/Captcha'
const SignupForm = () => {
  const [name, setName] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [nameError, setNameError] = useState('')
  const [otp, setOTP] = useState('')
  const [errorMessage, setErrorMessage] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [userNameError, setUserNameError] = useState("")
  const [otpError, setOTPError] = useState("")
  const [email, setEmail] = useState('')
  const [mobileNumber,setMobileNumber] = useState('')
  const [emailError,setEmailError] = useState('')
  const [mobileNumberError,setMobileNumberError] = useState('')
  const [userNameISMobile,setUserNameIsMobile]= useState(false)
  const [userNameISEmail,setUserNameIsEmail]= useState(false)
  const [signupStep,setSignupStep] = useState(1);
  const [loading,setLoading] = useState(false)
  const [signupSuccess,setSignupSuccess] =useState(false)

  const onChangeName = (e) => {
    const name = e.target.value
    setName(name)
    setNameError('')
    setErrorMessage('')
    setUsernameErrorMessage('')
  }
  
  const onChangePassword = (e) => {
    const password = e.target.value
    setPasswordError('')
    setErrorMessage('')
    setPassword(password)
    setUsernameErrorMessage('')
  }

  const onChangeMobileNumber = (e) => {
    const mobile = e.target.value
    setMobileNumberError('')
    setErrorMessage('')
    setMobileNumber(mobile)
    setotpErrorMessage('')
  }

  const onChangeEmail = (e) => {
    const email = e.target.value
    setEmailError('')
    setErrorMessage('')
    setEmail(email)
    setotpErrorMessage('')
  }

  const onChangeOTP = (e) =>{
    const otp = e.target.value
    if (/^\d{0,6}$/.test(otp)){
      setOTP(otp)
    }
    setOTPError('')
    setErrorMessage('')
  }

  const navigate= useNavigate()
  const handleRegister = (e) => {
    e.preventDefault()
    var isNameVerified = validateName(name,setNameError)
    var isPasswordVerified = validatePassword(password,setPasswordError)
    if(isPasswordVerified&&isNameVerified){
      setLoading(true)
          LoginCustomer(name,email,mobileNumber,password,navigate,window,setErrorMessage,setLoading,setSignupSuccess,setUsernameErrorMessage)
      }
  }

  const verifyStep1 =(e)=>{
    e.preventDefault()
     var isValidEmail = validateEmail(email,setEmailError)
     if(isValidEmail){
      setLoading(true)
      setMobileNumber('')
      sendEmailOTPtoCustomer(email,setSignupStep,setLoading)
      setUserName(email)
      setUserNameIsEmail(true)
    }
    var isValidMobileNumber = validateMobile(mobileNumber,setMobileNumberError)
    if(isValidMobileNumber){
      setLoading(true)
      setEmail('')
      sendMobileOTPtoCustomer(mobileNumber,setSignupStep,selectedCountryCode.countryCode,setLoading,setotpErrorMessage)
      setUserName(mobileNumber)
      setUserNameIsMobile(true)
    }
  }

  const verifyStep2 =(e)=>{
    e.preventDefault()
    var isOTPVerified =  validateIsOtp(otp,setOTPError)
    if(isOTPVerified){
      if(userNameISEmail){
         verifyCustomerEmailOTP(email,otp,setSignupStep,setOTPError)
      }
      else if(userNameISMobile){
         verifyCustomerMobileOTP(mobileNumber,otp,setSignupStep,setOTPError)
      }
    }
  }
  const[userNameErrorMessage,setUsernameErrorMessage] =useState('')
  const[otpErrorMessage,setotpErrorMessage] =useState('')
  const [selectedCountryCode, setSelectedCountryCode] = useState({ countryName: 'India', countryCode: '91' });
  const handleCountrySelect = (index) => {
    setSelectedCountryCode(index)
    console.log("selected country = ",selectedCountryCode)
  };
  return (
    <div className="maindiv">
      <div className='form__header'>
      <h1>Create your account</h1>
      <h4>Already have an account? <label className='label__tosignup'><Link to="/login">Log In→</Link></label></h4>
    </div>
    <div className="containersignupform">
      {signupStep===1&&(
        <>
      <form onSubmit={verifyStep1} className="formsignup">
      {otpErrorMessage!='' &&<p style={{width:'100%',textAlign:'center',color:'red'}}>{otpErrorMessage}</p>}
      <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'center',}}>
        <CountryComboBox onSelect={handleCountrySelect}></CountryComboBox>
        <TextField
                label="Mobile Number"
                value={mobileNumber}
                onChange={onChangeMobileNumber}
                type="text"
                placeholder="Enter your mobilenumber"
                error={Boolean(mobileNumberError)}
                fullWidth
                sx={{marginTop:'5px'}}
                className="txtbox"
                id='txtfld__username'
                helperText={mobileNumberError}
              /></div>
              <h5 className='or'>or</h5>
              <TextField
                label="Email"
                value={email}
                onChange={onChangeEmail}
                type="text"
                placeholder="Enter your email address"
                error={Boolean(emailError)}
                fullWidth
                sx={{marginTop:'20px'}}
                className="txtbox"
                id='txtfld__username'
                helperText={emailError}
              />
              <Captcha/>
        <button className="btn btn_signup">Verify OTP</button>
        <ToastContainer />
      </form>
      {/* <div style={{ textAlign: 'center' }} className="signup">
        <span className="signup">Already have an account? 
          <span> </span>
          <label for="check"><Link to="/login">Login</Link></label>
        </span>
      </div> */}
      </>
      )}
      {signupStep === 2 && (<>
      <form onSubmit={verifyStep2} className="formsignup">
      <label className='lbl__otpsent'>
        OTP sent to {userName}
      </label>
      <TextField
                label="OTP"
                value={otp}
                onChange={onChangeOTP}
                type="number"
                placeholder="Enter your 6 digit OTP"
                fullWidth
                sx={{marginTop:'20px'}}
                error={Boolean(otpError)}
                className="txtbox"
                id='txtfld__otp'
                helperText={otpError}
              />
        <button style={{marginTop:'10px'}} className="btn btn_signup">Verify</button>
        <ToastContainer />
      </form>
      </>)}
      {signupStep === 3 && (<>
      <form onSubmit={handleRegister} className="formsignup">
      {userNameErrorMessage!=''&&<p style={{width:'100%',textAlign:'center',color:'red'}}>{userNameErrorMessage}</p>}
      <TextField
                label="Mobile or Email"
                value={userName}
                id='txtfld__username'
                type="text"
                placeholder="Enter your mobilenumber or email"
                error={Boolean(userNameError)}
                fullWidth
                sx={{marginTop:'20px'}}
                className="txtbox"
                disabled
                helperText={userNameError}
              />
      <TextField
                label="Name"
                value={name}
                onChange={onChangeName}
                type="text"
                placeholder="Enter your mobilenumber or email"
                fullWidth
                id='txtfld__name'
                sx={{marginTop:'20px'}}
                error={Boolean(nameError)}
                className="txtbox"
                helperText={nameError}
              />
      <TextField
                label="Password"
                value={password}
                onChange={onChangePassword}
                type="password"
                id='txtfld__password'
                placeholder="Enter your password"
                error={Boolean(passwordError)}
                fullWidth
                className="txtbox"
                sx={{marginTop:'20px'}}
                helperText={passwordError}
              />
              <Captcha/>
        <button className="btn btn_signup">Signup</button>
        <ToastContainer />
      </form>
      </>)}
      {loading && <Loading/>}
      {signupSuccess && <CustomizedSnackbar text={"signup completed successfully"}/>}
    </div>
  </div>
  )
}


export default SignupForm