import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useRef, useEffect } from 'react'
import './SearchBusiness.css'
import BusinessCardView from './Business/BusinessCardView'
import CustomerAPIService from '../../services/ApiService'
import CityAutocomplete from './CityAutocomplete'
import StateAutocomplete from './StateAutocomplete'
import { searchBusiness } from './ServiceSearch'
import OutlinedInput from '@mui/material/OutlinedInput';
import { ImSearch } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import SettingsDrawer from '../Settings/SettingsDrawer'

const SearchBusiness = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [selectedCity, setSelectedCity] = useState('')
  const searchButtonRef = useRef(null)
  const [responseData, setResponseData] = useState([])
  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
    console.log('city',selectedCity)
    searchBusiness(e.target.value,selectedCity,setResponseData,setFilteredData)
  }

  useEffect(() => {
    sessionStorage.removeItem('selectedBusiness')
    const handleKeyPress = (event) => {
      if (event.key === 'Enter'&& searchButtonRef.current) {
        searchButtonRef.current.click()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const handleClearData = () => {
    setSearchTerm('')
    setFilteredData([])
  }

  return (
    <>
    {/* <div className='maindivsearch'>
   <div className="search-bar">
    <div className='search'>
    <div className="search__business">
    <h5>Business name</h5>
    <OutlinedInput id="search-input" sx={{border:'none'}} className='txtbox' fullWidth value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..."></OutlinedInput>
      </div>

      <div className='search__state'>
      <h5>State</h5>
      <StateAutocomplete  onStateSelected={handleStateSelected}></StateAutocomplete>
      </div>
      <div class="search__city">
      <h5>City</h5>
        <CityAutocomplete onCitySelected={handleCitySelected} state={selectedState}></CityAutocomplete>
      </div>
      <div class="search__button">
      <button className='button__search' ref={searchButtonRef} onClick={handleSearch}>
        Search
      </button>
      </div>
      </div>
      
     
   </div>
    </div> */}
    <div className='select__location'>
      <SettingsDrawer setCity={setSelectedCity}/>
    </div>
    <div className='maindivsearch'>
      <div className='search__bar'>
          <input className='input__search' autoComplete="off" value={searchTerm} onChange={handleSearch} placeholder='Search for business...' type='text'></input>
          {searchTerm === '' ? 
           <ImSearch  style={{fontSize:'1.5rem',marginLeft:'1rem',marginRight:'0.5rem'}} className='icon__search'/>
          :<IoMdClose onClick={handleClearData} style={{fontSize:'1.5rem',marginLeft:'1rem',marginRight:'0.5rem'}} />}  
      </div>
      </div>
      {filteredData.length===0&&searchTerm!='' &&<p>no results found for business name starts with '{searchTerm}'...</p>}
      {/* <div>
        <p>1 business found...</p>
        <p>no results found for business name starts with '{searchTerm}'...</p>
      </div> */}
    <div className='search__results'>
    <div className="search__card">
    {filteredData.map(data=>(
          <div className="search__container">
            <BusinessCardView businessData={data}/>
        </div>
    ))}
    </div>
    </div>
     </>
)
}

export default SearchBusiness