import React, { useEffect,useState } from 'react'
import './CheckinProgress.css'
import { connect, disconnect } from '../../../services/SocketService'
import CustomerAPIService from '../../../services/ApiService'
import ParentComponent, { ThreeStepStepper } from './CheckinStatusStepper'
import CustomizedSteppers from './CheckinStatusStepper'
import { CheckinStatusEnum } from '../../../data/contants'
import { CancelCheckIn } from '../ServiceCheckin'
import NavbarAfterLoggedin from '../../Navbar/NavbarAfterLoggedin/NavbarAfterLoggedin'
const CheckinProgress = ({customerCheckinData}) => {
  const [userName,setUserName]=useState('')
  const user = CustomerAPIService.getCurrentUser()
  useEffect(() => {
    sessionStorage.removeItem('selectedBusiness')
    if(customerCheckinData.status===CheckinStatusEnum.STATUS_ENROLL){
      setActiveStep(0)
    }else if(customerCheckinData.status===CheckinStatusEnum.STATUS_SERVICE_IN_PROGRESS){
      setActiveStep(1)
    }
    if (user !== null) {
      setUserName(user.name)
    }
      const onConnected = () => {
         console.log('Connected to WebSocket')
       }
   
       const onMessageReceived = (msg) => {
         const message = JSON.parse(msg.body)
         console.log('Received:', message)
       }
   
       const onCustomerDataReceived = (msg) => {
         const message = JSON.parse(msg.body)
         console.log('CustomerData:', message)
         window.location.reload()
       }
   
       connect(onConnected, onMessageReceived, onCustomerDataReceived,customerCheckinData.businessId)
   
       return () => {
         disconnect()
       }
   },[])

   const handleCancel=()=>{
    CancelCheckIn(user,customerCheckinData,window)
   }

   const [activeStep, setActiveStep] = useState(0);
       
    return (<>
        <div className='checkinprogess'>
         <div className='checkinprogessheader'>
            <label className='checkinprogessheaderlabel1'>You're checked in.</label>
            <label className='checkinprogessheaderlabel2'>See You Soon, {userName}</label>
            <CustomizedSteppers activeStep={activeStep}></CustomizedSteppers>
         </div>
         <div className='checkinprogesscontent'>
            <label className='checkinprogesscontentlbl3'>{customerCheckinData.businessName}</label>
            <label className='checkinprogesscontentlbl1'>YOUR ESTIMATED WAIT</label>
            <label className='checkinprogesscontentlbl2'>{customerCheckinData.estimationTime} Mins</label>
            <label className='checkinprogesscontentlbl3'>You're checked in</label>
            <label className='checkinprogesscontentlbl4'>you are in <span>{customerCheckinData.linePosition} </span>line</label>
            <button onClick={handleCancel} className='btncancel'>Cancel</button>
         </div>
        </div>
        </>
      )
}

export default CheckinProgress