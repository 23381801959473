import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import './LoginForm.css'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { TextField } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import CustomerAPIService from '../../services/ApiService'
import { validatePassword, validateUserName } from '../../data/inputValidations'
import ReCAPTCHA from "react-google-recaptcha";
import Loading from '../Features/Loading'
import CustomizedSnackbar from '../Features/SuccessSnackbar'
import Captcha from '../Features/Captcha'

const LoginForm = () => {
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [userNameError, setUserNameError] = useState("")
  const [errorMessage,setErrorMessage] = useState("")
  const [loading,setLoading] = useState(false)
  const [loginSuccess,setLoginSuccess] =useState(false)
  const onChangeUsername = (e) => {
    setErrorMessage('')
    setUserNameError('')
    const userName = e.target.value
    setUserName(userName)
    setErrorMessage('')
  }
  
  
  
  const onChangePassword = (e) => {
    const password = e.target.value
    setErrorMessage('')
    setPasswordError('')
    setPassword(password)
    setErrorMessage('')
  }
  const notifysuccess = () => {
    toast.success('login completed successfully!', {
      position: toast.POSITION.TOP_CENTER, 
      autoClose: 3000, 
      hideProgressBar: true, 
      closeOnClick: true,
      draggable: true,
      progress: undefined 
    })
  }
  const [captchaValue, setCaptchaValue] = useState('');
  const onChangeCaptcha = (value) => {
    setCaptchaValue(value);
    console.log(value)
  };

   const navigate = useNavigate()
    const verifyCustomerLogin=(e)=>{
      e.preventDefault()
      var isContactVerified = validateUserName(userName,setUserNameError)
      var isPasswordVerified = validatePassword(password,setPasswordError)
      if(isPasswordVerified&&isContactVerified){
      setLoading(true)
      CustomerAPIService.login(userName, password).then(
        (response) => {
          if (response.data.userName) {
            localStorage.setItem("c_user_token", JSON.stringify(response.data));
          }    
          setLoginSuccess(true)
          setTimeout(() => {
            navigate("/")
            setLoading(false)
            window.location.reload()
          }, 1500)
        }
        ).catch((error) => {
          console.log(error)
          setErrorMessage(error.response.data)
          setLoading(false)
        })
      }
      }
    
    
  return (
    <div class="maindiv">
    <div className='form__header'>
      <h1>Log in to your account</h1>
      <h4>Don't have an account? <label className='label__tosignup'><Link to="/signup">Sign up→</Link></label></h4>
    </div>
    <div class="containerloginform">
      <form class="formlogin">
        {errorMessage!=''&&<p style={{width:'100%',textAlign:'center',color:'red'}}>{errorMessage}</p>}
        <TextField
                label="Username"
                value={userName}
                onChange={onChangeUsername}
                type="text"
                placeholder="Enter your mobile number or email"
                error={Boolean(userNameError)}
                fullWidth
                id='txtfld__username'
                sx={{marginTop:'20px'}}
                helperText={userNameError}
                className="txtbox"
              />
        <TextField
                label="Password"
                value={password}
                onChange={onChangePassword}
                type="password"
                placeholder="Enter your password"
                error={Boolean(passwordError)}
                fullWidth
                id='txtfld__password'
                sx={{marginTop:'20px'}}
                helperText={passwordError}
                className="txtbox"
              />
              <Captcha/>
        <div>
            {loading && <Loading/>}
            {loginSuccess && <CustomizedSnackbar text={"logged in successfully"}/>}
          <button className='btn btn__login' onClick={verifyCustomerLogin}>Login</button>
        </div>
        <ToastContainer></ToastContainer> 
      </form>
      {/* <div style={{ textAlign: 'center' }} className="signup">
        <span>Don't have an account?{" "}
          <label><Link to="/signup"> Signup</Link></label>
        </span>
      </div> */}
    </div>
  </div>
  )
}

export default LoginForm