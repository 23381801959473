import React,{useState,useEffect} from 'react'
import "./home.css";
import { CheckinStatusEnum } from '../../data/contants';
import CustomerAPIService from '../../services/ApiService';
import CheckinProgress from '../../components/Checkin/Checkin-progress/CheckinProgress';
import SearchBusiness from '../../components/Search/SearchBusiness';
import { getCustomerCheckinStatus } from './ServiceHome';
import HomeHeader from '../../components/Home/Header/HomeHeader';
import NavbarHome from '../../components/Navbar/NavbarHome';
import NavbarAfterLoggedin from '../../components/Navbar/NavbarAfterLoggedin/NavbarAfterLoggedin';
import TokenVerification from '../../services/TokenVerification';
import Navbar from '../../components/Navbar/Navbar';

const Home = () => {
    const [customerCheckedIn,setCustomerCheckedIn] = useState('')
    const [customerCheckedInData,setCustomerCheckedInData]=useState([])
    useEffect(() => {
      const user = CustomerAPIService.getCurrentUser()
      if (user !== null) {
       getCustomerCheckinStatus(user,setCustomerCheckedIn,setCustomerCheckedInData)
      }else{
        setCustomerCheckedIn(CheckinStatusEnum.STATUS_NOT_CHECK_IN)
      }
    }, [])
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    useEffect(()=>{
      setIsLoggedIn(!TokenVerification.isTokenExpired())
    },[])
    return(
    <>
    <div className="homepagecontainer">
      {customerCheckedIn===''&&(<></>)}
      {customerCheckedIn===CheckinStatusEnum.STATUS_CHECK_IN &&(<>
      <NavbarAfterLoggedin/>
      <CheckinProgress customerCheckinData={customerCheckedInData}/>
      </>)}
      {customerCheckedIn===CheckinStatusEnum.STATUS_NOT_CHECK_IN&&(
      <>
      {isLoggedIn ? <NavbarHome/>:<Navbar/>}
      <HomeHeader/>
      <SearchBusiness/>
      </>)}
    </div>
    </>)
};

export default Home;
