import React from 'react'
import BusinessDetails from './BusinessDetails'
import BusinessStatus from './BusinessStatus'

const BusinessCardView = ({businessData}) => {
  return (<>
       <div className='business-cardview'>
        <div className='cardview-left'>
        <label>{businessData.name}</label>
          <BusinessDetails 
          status={businessData.status}
          phone={businessData.phone}
          address={businessData.address+", "+businessData.city+", "+businessData.state+"."}
          website={businessData.website}
          />
          </div>
          <BusinessStatus
          businessData={businessData}
          />
          </div>
          </>
  )
}

export default BusinessCardView