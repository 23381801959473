import axios from "axios"
import { ApiList } from "../data/contants"

const API_URL = ApiList.CUSTOMER_API_URL
const BUSINESS_API_URL = ApiList.BUSINESS_API_URL
const register = (name,email,phone,password) => {
  return axios.post(API_URL + "api/signup", {
    name,
    email,
    phone,
    password,
    role: ['user']
  })
}

const login = (userName, password) => {
  return axios
    .post(API_URL + "api/login", {
      userName,
      password,
    })
}
const search = (business,city) =>{
  return axios.get(BUSINESS_API_URL+"search/q",{
    params:{
      business,
      city
    }
  })
}

const getCityList = (state,cityName) =>{
  return axios.get(BUSINESS_API_URL+"address/city/from",{
    params:{
      state,
      cityName
    }
  })
}

const getStateList = (country) =>{
  return axios.get(BUSINESS_API_URL+"address/state/from",{
    params:{
      country
    }
  })
}

const logout = () => {
    localStorage.removeItem("c_user_token")
    return axios.post(API_URL + "api/signout").then((response) => {
      return response.data
    })
  }
  
  const logoutUser = () => {
    localStorage.removeItem("c_user_token")
  }
  
  
  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("c_user_token"))
  }


  const Checkin=(customerId,businessId,memberCount)=>{
     return axios.post(API_URL+"api/enroll",{
       customerId,
       businessId,
       memberCount
     }
     )
  }

  const CheckinStatus=(customerId,customerName)=>{
    return axios.post(API_URL+"api/enroll/status",{
      customerId,
      customerName
    })
  }

  const updateCheckInStatus = (businessId,customerId,status)=>{
    return axios.post(API_URL+'api/enroll/update',{
      businessId,
      customerId,
      status
    })
  }

  const sendEmailOTP = (email)=>{
    return axios.post(API_URL+'otp/email/send',{
      email
    })
  }

  const verifyEmailOTP = (email,otp)=>{
    return axios.post(API_URL+'otp/email/verify',{
      email,
      otp
    })
  }

  const sendMobileOTP = (mobile,countryCode)=>{
    return axios.post(API_URL+'otp/mobile/send',{
      mobile,
      countryCode
    })
  }

  const verifyMobileOTP = (mobile,otp)=>{
    return axios.post(API_URL+'otp/mobile/verify',{
      mobile,
      otp
    })
  }

  const verifyCaptcha = (response) =>{
    const secret = "6Lea-UspAAAAAKeXow2Twtkh7YMFFlo1GnLhqoIE"
    return axios.post("https://www.google.com/recaptcha/api/siteverify",{
      secret,
      response
    })
  }

  const citySearchFilter = (name) =>{
    const limit = 6
    return axios.get(BUSINESS_API_URL+'address/city/search',{
      params:{
        name,
        limit
      }})
  }
  
const CustomerAPIService = {
    register,
    login,
    logout,
    getCurrentUser,
    logoutUser,
    search,
    getCityList,
    getStateList,
    Checkin,
    CheckinStatus,
    updateCheckInStatus,
    sendEmailOTP,
    verifyEmailOTP,
    sendMobileOTP,
    verifyMobileOTP,
    verifyCaptcha,
    citySearchFilter
  }
  
  export default CustomerAPIService               