import { CheckinStatusEnum } from "../../data/contants"
import CustomerAPIService from "../../services/ApiService"

export const getCustomerCheckinStatus = (user,setCustomerCheckedIn,setCustomerCheckedInData) =>{
    CustomerAPIService.CheckinStatus(user.userName,'')
    .then((response)=>{
      if(response.data.status===CheckinStatusEnum.STATUS_SERVICE_COMPLETED||response.data.status===CheckinStatusEnum.STATUS_CANCELLED){
        setCustomerCheckedIn(CheckinStatusEnum.STATUS_NOT_CHECK_IN)
      }else{
        setCustomerCheckedIn(CheckinStatusEnum.STATUS_CHECK_IN)
        setCustomerCheckedInData(response.data)
        console.log(response.data)
      }
      console.log(response.data.status)
    })
    .catch((error)=>{
      setCustomerCheckedIn(CheckinStatusEnum.STATUS_NOT_CHECK_IN)
      console.log(error)
    })
}